import { useUser } from '@clerk/clerk-react';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Autocomplete from '@mui/joy/Autocomplete';
import { useMemo, useState } from 'react';
import { useUsers } from '../hooks/useUsers';
import useFilterStore from '../stores/filter';

export const UserSelect = () => {
  const { user, setUser, setInitialUserSet, initialUserSet } = useFilterStore();
  const usersQuery = useUsers();
  const userQuery = useUser();
  const [inputValue, setInputValue] = useState('');

  const users = usersQuery.data || [];

  useMemo(() => {
    // We only set the user once, on first load
    if (initialUserSet) {
      return;
    }

    if (!users.length) {
      return;
    }

    if (!userQuery.user) {
      return;
    }

    if (!user || user === 'none') {
      const aidaUser = users.find((u) => u.id === userQuery.user.id);
      if (!aidaUser) {
        console.error(`User ${userQuery.user.id} not found in users list`);
        return;
      }
      setUser(aidaUser.id);
      setInitialUserSet(true);
    }
  }, [usersQuery, userQuery, user, setUser]);

  return (
    <FormControl>
      <FormLabel>Gebruiker</FormLabel>
      <Autocomplete
        placeholder="Selecteer een gebruiker"
        sx={{ minWidth: '12rem' }}
        options={users.map((user) => user.id)}
        getOptionLabel={(option) =>
          users
            .find((u) => u.id === option)
            ?.email.split('@')[0]
            .replace('.', ' ') || ''
        }
        value={user}
        onChange={(_, newValue) => {
          if (!newValue) {
            setUser(null);
            return;
          }
          const userId = users.find((u) => u.id === newValue)?.id || '';
          setUser(userId);
        }}
        inputValue={inputValue}
        onInputChange={(_, newInputValue) => setInputValue(newInputValue)}
        isOptionEqualToValue={(option, value) => option === value}
      />
    </FormControl>
  );
};
