import MoreVert from '@mui/icons-material/MoreVert';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader';
import { RangeSelect } from '../../components/rangeSelect';
import Spinner from '../../components/spinner';
import { useNotes } from '../../hooks/notes/useNotes';
import { useAssignedAdministrations } from '../../hooks/useAssignedAdministrations';
import useDivision from '../../hooks/useDivision';
import { useMe } from '../../hooks/useMe';
import { useQualityByDivsion } from '../../hooks/useQualityByDivsion';
import useFilterStore from '../../stores/filter';
import ConfigureChecks from './configureChecks';
import { exportToPDF, transformDataPdf } from './export';
import CompletenessTable from './table';
import { ViewTypeRadioGroup, ViewTypeRadioGroupOptions } from './viewTypeRadioGroup';

type Props = {
  renderHeader?: boolean;
};

export default function CompletenessDrilldown({ renderHeader = true }: Props) {
  const params = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const { divisionId: divisionIdString } = params;
  const divisionId = divisionIdString as string;
  const divisionQuery = useDivision(divisionId);
  const { year, range } = useFilterStore();
  const completenessQuery = useQualityByDivsion(divisionId, range.yearStart, range.yearEnd, range.monthStart + 1, range.monthEnd + 1);
  const notesQuery = useNotes({
    administrationIds: [divisionId],
  });
  const userQuery = useMe();
  const assignedAdministrationsQuery = useAssignedAdministrations();
  const [viewType, setViewType] = useState<ViewTypeRadioGroupOptions>(ViewTypeRadioGroupOptions.checks);

  const onClickExport = () => {
    // exportToExcel(
    //   transformData(completenessQuery.data),
    //   `kwaliteit ${divisionQuery.data?.Description}`
    // );
  };

  const onClickExportPdf = async () => {
    await notesQuery.refetch();
    if (!notesQuery.isSuccess || !completenessQuery.data) {
      return;
    }
    const notes = notesQuery.data.map((note) => {
      const date = new Date(Number.parseInt(note.createdAt, 10)).toLocaleString('nl-NL').slice(0, 10);
      return `${date}: ${note.text}`;
    });

    exportToPDF(transformDataPdf(completenessQuery.data), `kwaliteit ${divisionQuery.data?.Description}`, completenessQuery.data.range, notes);
  };

  useEffect(() => {
    completenessQuery.refetch();
  }, [modalOpen, completenessQuery]);

  if (divisionQuery.isLoading) {
    return <Spinner />;
  }

  const canEdit =
    assignedAdministrationsQuery.data?.some((admin) => admin.AdministrationID === divisionId) ||
    userQuery.data?.Role === 'super' ||
    userQuery.data?.Role === 'admin';

  return (
    <Stack direction="column" gap={3}>
      {renderHeader && <PageHeader>{divisionQuery.data?.Description}</PageHeader>}

      <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction={'row'} gap={5}>
          <RangeSelect />
          <ViewTypeRadioGroup
            onChange={(newValue) => {
              setViewType(newValue);
            }}
            value={viewType}
          />
        </Stack>
        <Stack direction={'row'} gap={3}>
          <FormControl disabled={!notesQuery.isSuccess}>
            <FormLabel>{''}</FormLabel>
            <Dropdown placeholder="Export">
              <MenuButton sx={{ marginTop: '1rem' }} slots={{ root: IconButton }} slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}>
                <Typography sx={{ paddingX: '.75rem' }}>Export</Typography>
                <MoreVert />
              </MenuButton>
              <Menu>
                <MenuItem onClick={onClickExport} disabled>
                  Export Excel
                </MenuItem>
                <MenuItem onClick={onClickExportPdf}>Export PDF</MenuItem>
              </Menu>
            </Dropdown>
          </FormControl>

          <FormControl>
            <FormLabel>{''}</FormLabel>
            <Button
              endDecorator={<SettingsRoundedIcon />}
              onClick={() => {
                setModalOpen(true);
              }}
              disabled={!canEdit || !completenessQuery.isSuccess}
              sx={{ marginTop: '1rem' }}
            >
              Checks
            </Button>
          </FormControl>
        </Stack>
      </Stack>

      <CompletenessTable
        viewType={viewType}
        division={divisionId}
        year={year}
        monthEnd={range.monthEnd}
        monthStart={range.monthStart}
        yearStart={range.yearStart}
        yearEnd={range.yearEnd}
      />

      <ConfigureChecks divisionCode={divisionId} open={modalOpen} setOpen={setModalOpen} />
    </Stack>
  );
}
