import InfoIcon from '@mui/icons-material/Info';
import { Alert } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import DivisionSearch from '../../components/divisionSearch';
import { PageHeader } from '../../components/pageHeader';
import { RangeSelect } from '../../components/rangeSelect';
import { UserSelect } from '../../components/userSelect';
import useFilterStore from '../../stores/filter';
import { InvalidVatTable } from './table';

export const InvalidVatDashboard = () => {
  const { range, divisionSearch, user } = useFilterStore();

  return (
    <Stack direction="column" gap={3}>
      <PageHeader>Btw Dashboard</PageHeader>
      <Alert variant="soft" color="primary" startDecorator={<InfoIcon />}>
        Dit dashboard is in ontwikkeling. Dit dashboard geeft inzicht in periodes waarin btw bedragen zijn geboekt op grootboekrekeningen waar geen btw
        geboekt mag worden. (WBedAutBeb, WBedAdlBev, WBedVkkRep, WBedAdlBan, WFbeRflRfl, WBedAdlNbo, WBedOvpZie, WBedAssBea, WRevLoaLoaOvr, WRevOolOolVez,
        WOvbVezUib, WBedAssOva )
      </Alert>

      <Stack direction={'row'} sx={{ display: 'flex', justifyContent: 'space-between' }} gap={3}>
        <Stack direction={'row'} gap={3}>
          <DivisionSearch />

          <UserSelect />

          <RangeSelect />
        </Stack>
      </Stack>
      <InvalidVatTable
        yearStart={range.yearStart}
        yearEnd={range.yearEnd}
        monthStart={range.monthStart}
        monthEnd={range.monthEnd}
        divisionSearch={divisionSearch}
      />
    </Stack>
  );
};
